var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "1000" } },
    [
      _c(
        "v-card",
        { staticClass: "fill", attrs: { height: "800" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" " + _vm._s(_vm.selectedAd.ad_name) + " Notes "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-row",
                {
                  staticClass: "pa-4",
                  attrs: { dense: "", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "11", lg: "11" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          flat: "",
                          "hide-details": "",
                          outlined: "",
                          "no-resize": "",
                          rows: "3",
                          label: "Leave a note...",
                          "background-color": "input",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.saveAdNote.apply(null, arguments)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-0",
                                    attrs: { depressed: "" },
                                    on: { click: _vm.saveAdNote },
                                  },
                                  [_vm._v(" Post ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.note.note_text,
                          callback: function ($$v) {
                            _vm.$set(_vm.note, "note_text", $$v)
                          },
                          expression: "note.note_text",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                {
                  staticClass: "pa-4",
                  staticStyle: {
                    border: "1px solid grey",
                    "border-radius": "5px",
                    height: "500px",
                    "overflow-y": "auto",
                  },
                },
                _vm._l(_vm.notes, function (note, index) {
                  return _c(
                    "v-card",
                    { key: index, staticClass: "ma-2 pa-4" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h6 text-capitalize" },
                        [_vm._v(" " + _vm._s(note.audit.created_by) + " ")]
                      ),
                      _c("v-card-text", { staticClass: "pa-6" }, [
                        _c("p", { staticClass: "black--text" }, [
                          _vm._v(_vm._s(note.note_text)),
                        ]),
                      ]),
                      _c(
                        "v-card-actions",
                        { staticClass: "card" },
                        [
                          _c("v-spacer"),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(note.audit.created_on)
                                  .format(_vm.$dateConfig.timestamp)
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }